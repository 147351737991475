import React, { Component, Fragment } from 'react';

import styles from './email.less';

export default class Email extends Component {
  render() {
    return (
      <Fragment>
        <div className={styles.emailBox}>
          <div className={styles.emailMain}>
            <div className={styles.header}>
              <img src="https://images.dsers.com/logo_117_34.png" />
              <a />
            </div>
            <div className={styles.banner}>
              <img src="https://files.dsers.com/email_img/header1.png" />
            </div>
            <h3>Discover the best dropshipping tool!</h3>
            <p className={styles.pOne}>
              Dsers, an AliExpress project, keeps evolving. We simplified our
              interface and released a completely new tutorial to guide you step
              by step to place bulk orders.
            </p>
            <p className={styles.pTwo}>
              We are constantly releasing new features to better suit your
              needs. Check out the latest new highlights available for our
              dropshippers.
            </p>
            <div className={styles.vedioImg}>
              <a
                href="https://www.youtube.com/watch?v=6Em8KwD68-U"
                target="_blank"
              >
                <img src="https://files.dsers.com/email_img/vedioImgbig.png" />
              </a>
            </div>
            <div className={styles.btnWrap}>
              <a
                href="https://www.youtube.com/watch?v=3RknbSFRDc4&list=PLy0cC8peMaqnSj0heclOqKeAMoBVHEhYF"
                target="_blank"
              >
                TELL ME MORE
              </a>
            </div>
            <div className={styles.line}>
              {/* <img src="https://images.dsers.com/email_line.jpeg"/> */}
            </div>
            <h3 style={{ marginBottom: '40px' }}>
              Learn More About DSers, <br /> AliExpress partner
            </h3>
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=fcYNKwu_oSk"
                  target="_blank"
                >
                  <img src="https://files.dsers.com/email_img/vedioImg1.png" />
                </a>
                <div className={styles.vedioListTxt}>
                  {/* <img src="https://files.dsers.com/email_img/videotag.png"/> */}
                  <p>Click here to find out how to map product variants</p>
                </div>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=iTZCQrB9kHQ"
                  target="_blank"
                >
                  <img src="https://files.dsers.com/email_img/vedioImg2.png" />
                </a>
                <div className={styles.vedioListTxt}>
                  {/* <img src="https://files.dsers.com/email_img/videotag.png"/> */}
                  <p>Click here to find out how to set shipping methods</p>
                </div>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=3RknbSFRDc4&list=PLy0cC8peMaqnSj0heclOqKeAMoBVHEhYF"
                  target="_blank"
                >
                  <img src="https://files.dsers.com/email_img/vedioImg3.png" />
                </a>
                <div className={styles.vedioListTxt}>
                  {/* <img src="https://files.dsers.com/email_img/videotag.png"/> */}
                  <p>Click here to check our full YouTube tutorial</p>
                </div>
              </li>
            </ul>
            <a
              className={styles.started}
              href="https://www.dsers.com"
              target="_blank"
            >
              <i>GET STARTED</i>
            </a>
            {/* <div className={styles.line}>
                  <img src="https://images.dsers.com/email_line.jpeg"/>
              </div> */}
            <h3 style={{ marginTop: '32px' }}>DSers is working with</h3>
            <div className={styles.logos}>
              <img src="https://files.dsers.com/email_img/workwith.png" />
            </div>
            <div className={styles.contFoot}>
              <p>
                Want to know more about DSers?<br />
                <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">
                  Concact us→
                </a>
              </p>
            </div>
          </div>
          <div className={styles.footer}>
            <p className={styles.footerTitle}>
              <i>Contact with us on social media:</i>
            </p>
            <div className={styles.footerImgs}>
              <a
                href="https://www.facebook.com/DSers-2079192392398537/"
                target="_blank"
              >
                <img src="https://files.dsers.com/email_img/facebook-logo-button.png" />
              </a>
              <a
                href="https://www.youtube.com/playlist?list=PLy0cC8peMaqnSj0heclOqKeAMoBVHEhYF"
                target="_blank"
              >
                <img src="https://files.dsers.com/email_img/youtobe-icon.png" />
              </a>
              <a
                href="https://www.instagram.com/dsers_official"
                target="_blank"
              >
                <img src="https://files.dsers.com/email_img/instagram-logo.png" />
              </a>
            </div>
            <div className={styles.footerContent}>
              <p>
                <i>Copyright © 2018 DSers Co.Ltd., All rights reserved.</i>
              </p>
              <p>
                <i>
                  You are receiving this email because you signed up for DSers
                </i>
              </p>
              <p className={styles.footerContentHref}>
                {/* <a href="https://www.facebook.com/DSers-2079192392398537/" target="_blank">View in Browser</a> */}
                <a href="https://www.dsers.com/#/privacy" target="_blank">
                  Privacy Policy
                </a>
                <a
                  href="https://www.dsers.com/#/emailUnsubscribe"
                  target="_blank"
                >
                  Unsubscribe
                </a>
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
